*, ::after, ::before {
    box-sizing: border-box;
}

* {
    color: whitesmoke;
}

html, body, #root, .App {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.monospaced {
    font-family: monospace, monospace;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-align-center {
    justify-content: center;
}

.flex-align-end {
    justify-content: flex-end;
}

.flex-valign-center {
    align-items: center;
}

.flex-valign-end {
    align-items: flex-end;
}

.flex-space-between {
    justify-content: space-between;
}

.App {
    overflow-x: hidden;
    overflow-y: auto;
}

a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: color .4s ease;
    transition: color .4s ease;

    &, &:active, &:hover, &:focus, &:visited {
        text-decoration: none;
    }
}

.topbar {
    position: sticky;
    top: 0;
    z-index: 1000;

    background-color: #022c46;
    padding: 6px 0;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.header {
    position: relative;
    margin: 14px 0;

    .site-header__decor {
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        z-index: 99;
    }

    .site-header__decor-row {
        margin-left: -7px;
        margin-right: -7px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .site-header__decor-single {
        position: relative;
        padding-left: 7px;
        padding-right: 7px;
        flex: 0 0 100px;
    }

    [class*=site-header__decor-inner-]::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        opacity: 0.9;
    }

    .site-header__decor-inner-1::before {
        background-color: #1686f8;
    }

    .site-header__decor-inner-2::before {
        background-color: rgba(241, 229, 43, 0.94);
    }
}

.banner-wrapper {
    position: relative;
    background-color: #022c46;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-image: url("background.webp");
        background-size: cover;

        opacity: 0.08;
    }

    .banner {
        .container {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    .banner-title {
        position: relative;
        z-index: 100;

        b {
            font-weight: 1000;
            filter: sepia(1);
            white-space: nowrap;
        }
    }

    [class*=banner-bubble-] {
        animation-fill-mode: both;
        animation-timing-function: linear;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        transition: -webkit-transform .4s ease;
        transition: transform .4s ease, -webkit-transform .4s ease;
    }

    .banner-bubble-1 {
        position: absolute;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: rgba(243, 231, 43, 0.94);
        top: 40%;
        left: -3%;
        animation-name: bubbleMoveOne;
        transform-origin: top left;
    }

    .banner-bubble-2 {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgb(10, 150, 228);
        top: 5%;
        right: 5%;
        animation-name: bubbleMoveTwo;
        transform-origin: top right;
    }

    .banner-bubble-3 {
        position: absolute;
        width: 20px;
        height: 20px;
        transform: rotate(30deg);
        background-color: rgb(3, 107, 165);
        bottom: 0;
        right: 30%;
        animation-name: bubbleMoveThree;
        transform-origin: bottom right;
    }
}

.about-one, .about-two, .about-three {
    margin: 40px 0;
}

.about-one {
    .about-one__text {
        text-align: justify;
        margin: 0;
        font-size: 18px;
        line-height: 36px;
        color: #81868a;
    }

    .about-one__title {
        color: #022c46;
        text-align: center;
    }

    .about-one__btn {
        display: flex;
        justify-content: center;
        margin: 20px
    }
}

.about-two {
    .camp-wrapper {
        position: relative;
        background-color: #022c46;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-image: url("camp.webp");
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;

            opacity: 0.2;
        }
    }

    .camp-wrapper.ios {
        &::before {
            background-attachment: initial;
        }
    }

    .camp .container {
        padding-top: 150px;
        padding-bottom: 150px;

        .about-two__title {
            color: #ffffff;
            line-height: 70px;

            b {
                font-weight: 1000;
                filter: sepia(1);
            }
        }
    }

    .about-two__title {
        position: relative;
        z-index: 100;
        text-align: center;
        color: #022c46;
    }

    .about-two__text {
        position: relative;
        z-index: 100;
        margin-top: 20px;
        text-align: justify;
        font-size: 18px;
        line-height: 36px;
        color: #81868a;

        b {
            color: #81868a;
        }

        ul {
            margin: 0 10px;
            padding: 0 15px;

            li {
                text-align: left;
                color: #81868a;
            }
        }
    }

    .about-two__btn {
        display: flex;
        justify-content: center;
        margin: 20px
    }
}

.about-three {
    position: relative;

    .container {
        position: relative;
        z-index: 100;

        padding-top: 20px;
    }

    .about-three__banner {
        position: relative;
        height: 160px;
        background-color: rgba(243, 231, 43, 0.94);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url("background.webp");
            opacity: 0.1;
        }
    }

    .about-three__curve {
        width: 100%;
        height: 50px;
        background-image: url("curve.webp");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .about-three__text {
        text-align: justify;
        font-size: 18px;
        line-height: 36px;
        color: #81868a;

        ul {
            margin: 0 10px;
            padding: 0 15px;

            li {
                text-align: left;
                color: #81868a;
            }
        }

        .about-three__title {
            text-align: center;
            color: #022c46;
        }
    }

    .about-three__title {
        text-align: center;
        color: #ffffff;
    }

    .about-three__btn {
        display: flex;
        justify-content: center;
        margin: 20px
    }
}

.thm-btn {
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    background-color: #0a96e4;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    padding: 21px 68px;
    transition: all .4s ease;
}

.footer {
    margin-top: 20px;

    .container {
        margin-top: 20px;
        padding: 10px;
        background-color: #022c46;

        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .footer__map {
        flex: 0 0 50%;
    }

    .footer__contacts {
        flex: 0 0 50%;

        .flex {
            gap: 10px;
        }

        .footer__contacts-title {
            margin: 0;
            padding-bottom: 10px;
            text-align: right;
        }
    }

    .copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #022c46;

        color: #94a3ac;
        font-size: 10px;
        font-weight: 500;

        padding-bottom: 2px;

        .link {
            margin-left: 4px;
            font-size: 10px;
            font-weight: 500;
            color: rgba(243, 231, 43, 0.94);
        }
    }
}

.fb-icon, .insta-icon {
    img {
        filter: sepia(1) drop-shadow(1px 0px 2px #81868a);
    }
}

@keyframes bubbleMoveOne {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(10px, -10px);
    }
    20% {
        transform: translate(20px, -20px);
    }
    30% {
        transform: translate(30px, -30px);
    }
    40% {
        transform: translate(40px, -40px);
    }
    50% {
        transform: translate(50px, -50px);
    }
    60% {
        transform: translate(60px, -60px);
    }
    70% {
        transform: translate(70px, -70px);
    }
    80% {
        transform: translate(80px, -80px);
    }
    90% {
        transform: translate(90px, -90px);
    }
    100% {
        transform: translate(100px, -100px);
    }
}

@keyframes bubbleMoveTwo {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(-10px, 10px);
    }
    20% {
        transform: translate(-20px, 20px);
    }
    30% {
        transform: translate(-30px, 30px);
    }
    40% {
        transform: translate(-40px, 40px);
    }
    50% {
        transform: translate(-50px, 50px);
    }
    60% {
        transform: translate(-60px, 60px);
    }
    70% {
        transform: translate(-70px, 70px);
    }
    80% {
        transform: translate(-80px, 80px);
    }
    90% {
        transform: translate(-90px, 90px);
    }
    100% {
        transform: translate(-100px, 100px);
    }
}

@keyframes bubbleMoveThree {
    50% {
        transform: translate(150px, -200px) rotate(90deg);
    }
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media screen and (min-width: 576px) {
    .container {
        max-width: 540px;
    }

    .footer .container {
        max-width: 100%;
        margin-top: 20px;
        padding: 10px;
        flex-direction: row-reverse;

        .footer__contacts {
            padding: 40px;
        }
    }
}
